import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Layout from './layout'
import SEO from './seo'
import Img from 'gatsby-image'
import format from 'date-fns/format'

export default function({ data: { mdx } }) {
  const featuredImgFluid = mdx.frontmatter.cover.childImageSharp.fluid

  return (
    <Layout>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.shortDescription}
      />
      <div className="container articlePage">
        <Img
          fluid={featuredImgFluid}
          className="articlePage__Image"
          alt={mdx.frontmatter.title}
        />
        <div className="articlePage__Date">
          {format(new Date(mdx.frontmatter.date), 'MMMM Do, YYYY')}
        </div>
        <h1>{mdx.frontmatter.title}</h1>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        shortDescription
        date
        cover {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
